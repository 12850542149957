import { template as template_781251e8ef664c828c3b7583ea4f6fed } from "@ember/template-compiler";
const SidebarSectionMessage = template_781251e8ef664c828c3b7583ea4f6fed(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
