import { template as template_0d7326bc079b4d6d8aa1447a0e3cf37a } from "@ember/template-compiler";
const FKLabel = template_0d7326bc079b4d6d8aa1447a0e3cf37a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
